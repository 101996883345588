<template>
  <v-app>
    <v-main>
      <v-container>
        <v-card-text>
          <v-text-field
            v-model="password"
            outlined
            :rules="passwordRules"
            label="* Новый пароль"
          >
          </v-text-field>
          <v-btn
            text
            outlined
            color="dark"
            type="password"
            @click="savePassword"
          >
            Сохранить
          </v-btn>
        </v-card-text>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import account from "@/api/account";

export default {
  name: "UserAdminResetPassword",
  data() {
    return {
      password: "",
      passwordRules: [
        v => !!v || 'Это поле нужно заполнить',
        v => (v && v.length >= 8) || 'Пароль должен быть не менее 8 символов',
        v => (v && v.length <= 50) || 'Пароль должен быть не более 50 символов',
        v => /^[a-zA-Z0-9_]*$/.test(v) || 'Пароль может содержать только латинские символы и нижнее подчеркивание',
      ],
    };
  },
  computed: {
    validatePassword: function () {
      return !!this.password;
    },
  },
  methods: {
    savePassword: async function () {
      const userId = this.$route.params.id;
      if (this.validatePassword) {
        try {
          await account.adminResetPassword(userId, this.password);
          this.$store.dispatch("alertSuccess", "Пароль успешно изменен");
          this.$router.push({name: 'UserView', params: {id: userId}});
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
